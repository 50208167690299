export default {
  PROTOCOL: 'https',
  API_VERSION: 'v2',
  UZ_HOST: 'mediabay.uz',
  DEV_UZ_HOST: 'v1.mediabay.uz',
  TV_HOST: 'mediabay.tv',
  DEV_TV_HOST: 'v1.mediabay.tv',
  UZ_API_ENDPOINT: 'api.mediabay.uz',
  DEV_UZ_API_ENDPOINT: 'api.v1.mediabay.uz',
  TV_API_ENDPOINT: 'api.mediabay.tv',
  DEV_TV_API_ENDPOINT: 'api.v1.mediabay.tv',
};
