export const adSlots = [
  {
    // 300x600 schedule of TV player
    id: "adfox_16212399811879555",
    isMobile: false,
    params: {
      p1: "cpcrd",
      p2: "hfgp",
    },
  },
  {
    // 100% width content
    id: "adfox_162124105012135070",
    isMobile: false,
    params: {
      p1: "cpcrf",
      p2: "hfgq",
    },
  },
  {
    // Mobile 300x300 inside slider on home page
    id: "adfox_162124112030236039",
    isMobile: true,
    params: {
      p1: "cpcrh",
      p2: "hfgr",
    },
  },
  {
    // Mobile 300x300 on schedule block
    id: "adfox_162124117878818666",
    isMobile: true,
    params: {
      p1: "cpcrg",
      p2: "hfgr",
    },
  },
  {
    // Mobile fullscreen
    id: "adfox_165277382057596785",
    isMobile: true,
    type: "fullscreen",
    params: {
      p1: "cpcri",
      p2: "hfgs",
    },
  },
  {
    // Home slider .tv
    id: "adfox_166606909839924752",
    isMobile: false,
    type: "fullscreen",
    params: {
      p1: "bqnfg",
      p2: "ezyb",
      pfc: "eplel",
      pfb: "mqjym",
    },
  },
];
