export default {
  // Page titles
  homeTitle: 'Mediabay – Əsas səhifə',

  // Common
  oldSite: 'Saytın köhnə versiyası',
  pageError: 'Əlavədə xəta baş verdi...',
  pageNotFound: 'Səhifə tapılmadı',
  returnToHome: 'Əsas səhifəyə qayıtmaq',
  loadingError: 'Yükləmədə xəta baş verdi. Səhifəni yeniləməyə cəhd edin.',
  tvPlayer: 'Pleyer ve ya  TV pleyer ',
  watch: 'İzləmək',
  watchAll: 'Hamisini izləmək',
  buy: 'Almaq',
  active: 'Aktivdir',
  copyrightUz: 'OOO "Mediabay Asia"',
  copyrightTv: '"International Mediabay Alliance" LLC',
  policy: 'Политика конфиденциальности',
  yourIp: 'Ваш IP:',
  yourCountry: 'Ваша страна:',
  connection: 'Тип соединения',
  noSearchData: 'Sizin sorğunuz üzrə məlumat tapılmamışdır.',
  watchEverywhere: 'Mediabay-ı bütün cihazlardan izləyin',
  watchDevices: 'Smart TV, iPad və ya iPhone-dan, Android smartfon və  planşetlərindən, kompüter və noutbuklardan',
  allChannels: 'Bütün kanallar',
  details: 'İrəli',
  episodes: 'Epizodlar',
  filterLabel: 'Filtr',
  filterReset: 'Silmək',
  moviesGenres: 'Film janrları',
  serialsGenres: 'Serial janrları',
  podcastsGenres: 'Televiziya verilişi janrları',
  clipsGenres: 'Klip janrları',
  musicGenres: 'Musiqi janrları',
  cartoonsGenres: 'Çizgi film janrları',
  audiobooksGenres: 'Audio kitablar janrları',
  country: 'Ölkə',
  releaseYear: 'İl',
  language: 'Dil',

  // Not available data
  noData: 'Məlumat tapılmadı',
  noNotificationsData: 'Bildiriş yoxdur',
  noChannelsData: 'Kanalları yükləmək mümkün olmadı',
  noMediaData: 'Məlumatı yükləmək mümkün olmadı',
  noGuideData: 'Hal-hazırda açıq veriliş yoxdur',
  noTransactions: 'Sizin əməliyyat tarixçəniz boşdur',

  // Sections
  home: 'Əsas səhifə',
  tv: 'Onlayn TV',
  radio: 'Radio',
  newMovies: 'Kino yenilikləri',
  movies: 'Filmlər',
  serials: 'Seriallar',
  cartoons: 'Cizgi filmləri',
  podcasts: 'Teelviziya verilişləri',
  clips: 'Kilplər',
  music: 'Musiqi',
  audioBooks: 'Audio kitablar',
  favorites: 'Seçilmişlər',
  apps: 'Əlavəni yüklə',

  // TV Guide
  tvGuide: 'TV proqram',
  monday: 'Bazar ertəsi',
  tuesday: 'Çərşənbə axşamı',
  wednesday: 'Çərşənbə',
  thursday: 'Cümə axşamı',
  friday: 'Cümə',
  saturday: 'Şənbə',
  sunday: 'Bazar',
  live: 'Efirdə',
  nextItem: 'Növbəti',
  evenLater: 'Daha sonra',

  // Content genres
  freeTv: 'Ödənişsiz telekenallar',
  premiumTv: 'Ödənişli telekanallar',
  freeRadio: 'Radiostansiyalar',
  new: 'Yeniliklər',
  last: 'Son əlavə olunanlar',
  top: 'Populyar',
  recommended: 'Tövsiyə olunanlar',

  // Auth
  authEnter: 'Giriş',
  authReg: 'Qeydiyyatdan keçmək',
  rulesWarning: 'Qeydiyyatdan keçmək” düyməsini basaraq Siz qəbul edirsiniz ',
  signIn: 'Giriş',
  signUp: 'Qeydiyyat',
  authAction: 'olun və ya qeydiyyatdan keçin',
  name: 'Adınız',
  email: 'Elektron ünvanınız',
  emailOrLogin: 'Elektron ünvan və ya login',
  login: 'Login',
  password: 'Şifrə',
  confirmPassword: 'Şifrənin təstiqi',
  needEmailConfirm: 'Qeydiyyat müvəffəqiyyətlə tamamlanmışdır, təstiq Sizin elektron ünvanınıza göndərilmişdir',
  successfullyConfirm: 'Elektron ünvan uğurla təsdiqlənmişdir',
  successfullyValidated: 'Şifrə təstiqlənmişdir, yeni şifrə ilə daxil olun',
  errorConfirm: 'Elektron ünvanın aktivləşdirilməsində xəta',
  errorValidate: 'Yeni şifrənin aktivləşdirilməsində xəta',
  continue: 'Davam etmək',
  save: 'Yadda saxlamaq',
  forgotPassword: 'Şifrəni unutmusunuz?',
  resendConfirmEmail: 'Отправить письмо подтверждения повторно',

  // User
  logIn: 'Giriş',
  logOut: 'Çıxış',
  id: 'Sizin ID:',
  balance: 'Sizin balansınız:',
  expired: 'Abunə:',
  notActiveVoucher: 'aktiv deyildir',
  profileData: 'Şəxsi kabinet',
  settings: 'Ayarlar',
  subscribeChannelInfo: 'Ödənişli kanalı izləmək üçün abunəni aktivləşdirin',
  subscribeAction: 'Abunəni aktivləşdirmək',
  profileFavorites: 'Seçilmişlər',
  profileNoFavoritesData: 'Siz “Seçilmişlər”-ə heçnə əlavə etməmişsiniz',
  profilePay: 'Balansı artırmaq',
  profilePaymentLabel: 'Ödəniş məbləği',
  profilePaymentService: 'Ödəniş üsulunu seçin',
  profileServices: 'Xidmət və tariflər',
  profilePayAndBuy: 'Оплата и подписка',
  profilePayAndBuyForTv: 'Оплата',
  profileTransactions: 'Mənim əməliyyatlarım',
  profileTransactionsDate: 'Tarix',
  profileTransactionsDescr: 'Təsvir',
  profileTransactionsAmount: 'Məbləğ',
  profileSettings: 'Mənim ayarlarım',
  profileSettingsUpdateSuccess: 'Ayarlar müvəffəqiyyətlə yenilənmişdir',

  // Footer
  about: 'Xidmət haqqında',
  licenses: 'Lisenziyalar',
  rules: 'İstifadəçi Sazişi',
  contacts: 'Əlaqə',

  // PROMO
  promoCodeTitle: 'Активация промокода',
  promoCodeDescription: 'Активируйте Ваш промокод и получите доступ ко всем телеканалам',
  promoCodeHint: 'Введите промокод',
  promoCodeActivate: 'Активировать',
  promoCodeSuccessText: 'Ваш промокод успешно активирован',
  promoCodeCallToWatch: 'Начать просмотр',
};
