import './assets/fonts/font.css';

import * as serviceWorker from './serviceWorker';

import store, { history } from './store';

import App from './pages/App';
import { ConnectedRouter } from 'connected-react-router'
import ErrorBoundary from './hoc/ErrorBoundary';
import { Provider } from 'react-redux';
import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react'
import ScrollToTop from './hoc/ScrollToTop';
import axios from 'axios';
import config from './config';
import en from './languages/en';
import { i18nActions } from 'redux-react-i18n';
import { render } from 'react-dom';
import ru from './languages/ru';
import tj from './languages/tj';
import uz from './languages/uz';
import az from './languages/az';

axios.defaults.withCredentials = true;

// Languages setup
store.dispatch(i18nActions.setLanguages([
  { code: 'ru', name: 'English' },
  { code: 'en', name: 'Русский' },
  { code: 'uz', name: 'O\'zbek' },
  { code: 'tj', name: 'Тоҷикӣ' },
  { code: 'az', name: 'Azeri' },
]));

// Initialization of error tracking
const bugsnagClient = bugsnag({
  apiKey: '6677ecc11be32341b21500ef9bab6067',
  notifyReleaseStages: ['production'],
});

bugsnagClient.use(bugsnagReact, React);
const ErrorWrapper = bugsnagClient.getPlugin('react');


const lang = localStorage.getItem('lang');

store.dispatch(i18nActions.setDictionaries({ en, ru, uz, tj, az }));
store.dispatch(i18nActions.setCurrentLanguage(lang || 'ru'));

const renderApp = () => (
  render(
    <ErrorWrapper FallbackComponent={ErrorBoundary}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </ConnectedRouter>
      </Provider>
    </ErrorWrapper>,
    document.getElementById('root'),
  )
);

// We have to get API ENDPOINT .uz or .tv
// For dev mode we disable this feature and use .uz server by default
if (process.env.NODE_ENV === 'development') {
  localStorage.setItem('isUzb', true);
  axios.defaults.baseURL = `${config.PROTOCOL}://${config.UZ_API_ENDPOINT}/${config.API_VERSION}/`;
  renderApp();
} else {
  // Production
  const {
    DEV_UZ_HOST,
    UZ_HOST,
    DEV_TV_HOST,
    TV_HOST,
    DEV_UZ_API_ENDPOINT,
    UZ_API_ENDPOINT,
    DEV_TV_API_ENDPOINT,
    TV_API_ENDPOINT,
  } = config;

  const { host } = window.location;

  // Handle API ENDPOINT for dev/prod and .uz/.tv servers
  switch(host) {
    case DEV_UZ_HOST:
      localStorage.setItem('isUzb', 'true');
      axios.defaults.baseURL = `//${DEV_UZ_API_ENDPOINT}/${config.API_VERSION}/`;
      break;
    case UZ_HOST:
    case `www.${UZ_HOST}`:
      localStorage.setItem('isUzb', 'true');
      axios.defaults.baseURL = `//${UZ_API_ENDPOINT}/${config.API_VERSION}/`;
      break;
    case DEV_TV_HOST:
      localStorage.setItem('isUzb', 'false');
      axios.defaults.baseURL = `//${DEV_TV_API_ENDPOINT}/${config.API_VERSION}/`;
      break;
    case TV_HOST:
    case `www.${TV_HOST}`:
      localStorage.setItem('isUzb', 'false');
      axios.defaults.baseURL = `//${TV_API_ENDPOINT}/${config.API_VERSION}/`;
      break;
    default:
      localStorage.setItem('isUzb', 'false');
      axios.defaults.baseURL = `//${TV_API_ENDPOINT}/${config.API_VERSION}/`;
  }

  // Then render app with isUzb flag and API ENDPOINT
  renderApp();
}

// Unregister service worker
serviceWorker.unregister();
