export default {
  // Page titles
  homeTitle: 'Mediabay - Bosh sahifa',

  // Common
  oldSite: 'Saytning eski versiyasi',
  pageError: 'Ilovada xato kelib chiqdi...',
  pageNotFound: 'Sahifa topilmadi',
  returnToHome: 'Bosh sahifaga qaytish',
  loadingError: 'Yuklashdagi xato. Sahifani yangilab ko\'ring',
  tvPlayer: 'TV pleyer',
  watch: 'Ko\'rish',
  watchAll: 'Barchasini ko\'rish',
  buy: 'Xarid qilish',
  active: 'Faol',
  copyrightUz: 'MCHJ "Mediabay Asia"',
  copyrightTv: '"International Mediabay Alliance" LLC',
  policy: 'Политика конфиденциальности',
  yourIp: 'Ваш IP:',
  yourCountry: 'Ваша страна:',
  connection: 'Тип соединения',
  noSearchData: 'Sizning so\'rovingiz bo\'yicha xech narsa topilmadi',
  watchEverywhere: 'Mediabay\'ni barcha uskunalarda ko\'ring',
  watchDevices: 'Smart TV, iPad yoki iPhone, Android smartfonlar va planshetlar, kompyuter va noutbuklarda',
  allChannels: 'Barcha kanallar',
  details: 'Ko\'rib chiqish',
  episodes: 'Epizodlar',
  filterLabel: 'Filtr',
  filterReset: 'Tozalash',
  moviesGenres: 'Filmlar janrlari',
  serialsGenres: 'Seriallar janrlari',
  podcastsGenres: 'Teleko\'rsatuvlar janrlari',
  clipsGenres: 'Klaplar janrlari',
  musicGenres: 'Musiqa janrlari',
  cartoonsGenres: 'Multfilmlar janrlari',
  audiobooksGenres: 'Audiokitoblar janrlari',
  country: 'Mamlakat',
  releaseYear: 'Yil',
  language: 'Til',

  // Not available data
  noData: 'Ko\'rsatish uchun ma\'lumotlar yo\'q',
  noNotificationsData: 'Xabarlar yo\'q',
  noChannelsData: 'Kanallar yuklab olinmadi',
  noMediaData: 'Ma\'lumotlar yuklab olinmadi',
  noGuideData: 'Hozirda ko\'rsatuvlar dasturi mavjud emas',
  noTransactions: 'Sizda hali tranzaksiyalar yo\'q',

  // Sections
  home: 'Bosh sahifa',
  tv: 'Onlayn TV',
  radio: 'Radio',
  newMovies: 'Yangi kino',
  movies: 'Filmlar',
  serials: 'Seriallar',
  cartoons: 'Multfilmlar',
  podcasts: 'Тeleko\'rsatuvlar',
  clips: 'Kliplar',
  music: 'Musiqa',
  audioBooks: 'Audiokitoblar',
  favorites: 'Saralanganlar',
  apps: 'Ilovani yuklash',

  // TV Guide
  tvGuide: 'Ko\'rsatuvlar dasturi',
  monday: 'Du',
  tuesday: 'Se',
  wednesday: 'Cho',
  thursday: 'Pa',
  friday: 'Ju',
  saturday: 'Sha',
  sunday: 'Ya',
  live: 'Efirda',
  nextItem: 'Keyin',
  evenLater: 'So\'ng',

  // Content genres
  freeTv: 'Bepul telekanallar',
  premiumTv: 'Pullik telekanallar',
  freeRadio: 'Radiostansiyalar',
  new: 'Yangilari',
  last: 'So\'nggi qo\'shilganlar',
  top: 'Ommabop',
  recommended: 'Tavsiya qilingan',

  // Auth
  authEnter: 'Kirish',
  authReg: 'Ro\'yxatdan o\'tish',
  rulesWarning: '"Ro\'yxatdan o\'tish" tugmachasini boskanda siz qabul qilasiz',
  signIn: 'Kirish',
  signUp: 'Ro\'yxatdan o\'tish',
  authAction: 'Kiring yoki ro\'yxatdan o\'ting',
  name: 'Sizning ismingiz',
  email: 'Sizning email\'ingiz',
  emailOrLogin: 'Email yoki login',
  login: 'Login',
  password: 'Parol',
  confirmPassword: 'Parolni tasdiqlash',
  needEmailConfirm: 'Ro\'yxat muvaffaqiyatli o\'tdi, tasdiq sizning email\'ga yuborildi',
  successfullyConfirm: 'Email muvaffaqiyatli yakunlandi',
  successfullyValidated: 'Parol tasdiqlandi, yangi parol bilan avtorizatsiyadan o\'ting',
  errorConfirm: 'email\'ni aktivatsiya qilishda xato',
  errorValidate: 'Parolni aktivatsiya qilishda xato',
  continue: 'Davom etish',
  save: 'Saqlash',
  forgotPassword: 'Parolni unutdingizmi?',
  resendConfirmEmail: 'Отправить письмо подтверждения повторно',

  // User
  logIn: 'Kirish',
  logOut: 'Chiqish',
  id: 'Siznging ID:',
  balance: 'Sizning balans:',
  expired: 'Obuna:',
  notActiveVoucher: 'Faol emas',
  profileData: 'Shaxsiy kabinet',
  settings: 'Sozlamalar',
  subscribeChannelInfo: 'Pullik kanalni ko\'rish uchun obunani aktivatsiya qiling',
  subscribeAction: 'Obunani aktivatsiya qilish',
  profileFavorites: 'Saralangan',
  profileNoFavoritesData: 'Siz saralanganga xech narsa qo\'shmadingiz',
  profilePay: 'Balansni to\'ldirish',
  profilePaymentLabel: 'To\'ldirish summasi',
  profilePaymentService: 'To\'lov turini tanlang',
  profileServices: 'Xizmatlar va tariflar',
  profilePayAndBuy: 'To\'lov va obuna',
  profilePayAndBuyForTv: 'Hisobni to\'ldirish',
  profileTransactions: 'Мening operatsiyalarim',
  profileEnterPromocode: 'Promo-kodni kiritish',
  profileTransactionsDate: 'Sana',
  profileTransactionsDescr: 'Tavsif',
  profileTransactionsAmount: 'Summa',
  profileSettings: 'Mening sozlamalarim',
  profileSettingsUpdateSuccess: 'Sozlamalar muvaffaqiyatli yangilandi',

  // Footer
  about: 'Servis haqida',
  licenses: 'Litsenziyalar',
  rules: 'Foydalanuvchilik kelishuvi',
  contacts: 'Aloqa',

  // PROMO
  promoCodeTitle: 'Promokod aktivatsiyasi',
  promoCodeDescription: 'Diqqat! Mobil trafik sarflanadi! Promo-kod servisdagi  turli xil kontent va telekanallarni ko\'rish imkonini beradi',
  promoCodeHint: 'Promo-kodni kiriting',
  promoCodeActivate: 'Faollashtirish',
  promoCodeSuccessText: 'Promo-kodingiz muvaffaqiyatli ishga tushirildi',
  promoCodeSuccessMoneyText: 'Ваш бонус зачислен',
  promoCodeCallToWatch: 'Ko\'rishni boshlang',
};
