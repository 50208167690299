import initialState from './initialState';
import {
  GET_INITIAL_USER_INFO,
  CHECK_EMAIL,
  CONFIRM_USER_EMAIL,
  USER_SIGN_IN,
  USER_SIGN_UP,
  GET_USER_TRANSACTIONS,
  GET_AVAILABLE_VOUCHERS,
  UPDATE_USER_DATA,
  USER_SIGN_OUT,
} from '../constants';

export default (state = initialState.user, action) => {
  switch (action.type) {
    case GET_INITIAL_USER_INFO:
      return {
        ...state,
        isAuthorized: action.response.status === 200 && action.response.data.message !== 'Bad Credentials',
        hasVoucher: action.response.status === 200 && action.response.data.data.vouchers.length !== 0,
        profileData: action.response.status === 200 && action.response.data.message !== 'Bad Credentials' ? (
          action.response
        ) : state.profileData,
      };

    case CHECK_EMAIL:
      return {
        ...state,
        isExistsEmail: action.response.data.registered,
      };

    case USER_SIGN_IN:
      return {
        ...state,
        isAuthorized: action.response.data.status === 'ok',
        profileData: action.response,
      };

    case USER_SIGN_UP:
      return {
        ...state,
        profileData: action.response,
      };

    case USER_SIGN_OUT:
      return {
        ...state,
        isAuthorized: action.response.data.message !== 'Successfully logged out',
        isExistsEmail: null,
        profileData: action.response.data.message === 'Successfully logged out' ? (
          {
            data: {
              data: {
                id: 0,
                email: '',
                name: '',
                sessionId: '',
                balance: '',
              },
            },
          }
        ) : state.profileData,
      };

    case GET_USER_TRANSACTIONS:
      return {
        ...state,
        transactions: action.response,
      };

    case GET_AVAILABLE_VOUCHERS:
      return {
        ...state,
        availableVouchers: action.response,
      };

    case UPDATE_USER_DATA:
      return {
        ...state,
        isSettingsUpdated: action.response.data.message === 'Successfully Changed',
      };

    case CONFIRM_USER_EMAIL:
      return {
        ...state,
        signUpFormData: action.response,
      };

    default:
      return state;
  }
};
