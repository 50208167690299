import initialState from './initialState';
import {
  GET_TV_THREAD,
  GET_TV_CHANNEL,
  GET_TV_CHANNELS,
  GET_TV_CHANNELS_BY_COUNTRY,
} from '../constants';

export default (state = initialState.tv, action) => {
  switch (action.type) {
    case GET_TV_THREAD:
      return {
        ...state,
        tvChannelThread: action.response,
      };

    case GET_TV_CHANNEL:
      return {
        ...state,
        tvChannel: action.response,
      };

    case GET_TV_CHANNELS:
      return {
        ...state,
        tvChannels: action.response,
      };

    case GET_TV_CHANNELS_BY_COUNTRY:
      return {
        ...state,
        tvChannelsByCountry: action.response,
      };

    default:
      return state;
  }
};
