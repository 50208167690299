import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import sprite from './icons.svg';

const StyledSvg = styled.svg`

  vertical-align: middle;
  fill: ${props => props.color};
  outline: 0;
`;

const Icon = props => (
  <StyledSvg
    width={`${props.width}px`}
    height={`${props.height}px`}
    {...props}
  >
    <use xlinkHref={`${sprite}#${props.icon}`} />
  </StyledSvg>
);

Icon.defaultProps = {
  width: 26,
  height: 26,
  color: '#fff',
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Icon;
