import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { adSlots } from "./adSlots";

const AdFoxBanner = React.memo(({ slot }) => {
  const hasVoucher = useSelector((state) => state.user.hasVoucher);

  useEffect(() => {
    const media = window.matchMedia("(max-width: 1024px)");
    const isMobile = media.matches;

    if (!hasVoucher && window.Ya && adSlots[slot].isMobile === isMobile) {
      window.yaContextCb.push(() => {
        window.Ya.adfoxCode &&
          window.Ya.adfoxCode.createAdaptive(
            {
              ownerId: 212276,
              containerId: adSlots[slot].id,
              params: adSlots[slot].params,
              ...(adSlots[slot].type ? { type: adSlots[slot].type } : {}),
            },
            [...(isMobile ? ["phone", "tablet"] : ["desktop"])],
            {
              tabletWidth: 1024,
              phoneWidth: 768,
              isAutoReloads: false,
            }
          );
      });
    } else {
      window.yaContextCb = [];
    }
  }, [hasVoucher, slot]);

  if (!hasVoucher) {
    return <div id={adSlots[slot].id} />;
  }

  return <div />;
});

export default AdFoxBanner;
