export default {
  // Page titles
  homeTitle: 'Mediabay - Главная страница',

  // Common
  oldSite: 'Старая версия сайта',
  pageError: 'В приложении произошла ошибка...',
  pageNotFound: 'Страница не найдена',
  returnToHome: 'Вернуться на главную',
  loadingError: 'Ошибка загрузки. Попробуйте обновить страницу',
  tvPlayer: 'ТВ плеер',
  watch: 'Смотреть',
  watchAll: 'Смотреть всё',
  buy: 'Купить',
  active: 'Активна',
  copyrightUz: 'OOO "Mediabay Asia"',
  copyrightTv: '"International Mediabay Alliance" LLC',
  policy: 'Политика конфиденциальности',
  yourIp: 'Ваш IP:',
  yourCountry: 'Ваша страна:',
  connection: 'Тип соединения',
  noSearchData: 'По вашему запросу ничего не найдено',
  watchEverywhere: 'Смотрите Mediabay на всех устройствах',
  watchDevices: 'Smart TV, iPad или iPhone, смартфонах и планшетах Android, компьютерах и ноутбуках',
  allChannels: 'Все каналы',
  details: 'Перейти',
  episodes: 'Эпизоды',
  filterLabel: 'Фильтр',
  filterReset: 'Очистить',
  moviesGenres: 'Жанры фильмов',
  serialsGenres: 'Жанры сериалов',
  podcastsGenres: 'Жанры телепередач',
  clipsGenres: 'Жанры клипов',
  musicGenres: 'Жанры музыки',
  cartoonsGenres: 'Жанры мультфильмов',
  audiobooksGenres: 'Жанры аудиокниг',
  country: 'Страна',
  releaseYear: 'Год',
  language: 'Язык',

  // Not available data
  noData: 'Нет данных для отображения',
  noNotificationsData: 'Нет уведомлений',
  noChannelsData: 'Не удалось загрузить каналы',
  noMediaData: 'Не удалось загрузить данные',
  noGuideData: 'Сейчас нет доступной телепрограммы',
  noTransactions: 'У вас еще нет истории транзакций',

  // Sections
  home: 'Главная',
  tv: 'Онлайн ТВ',
  radio: 'Радио',
  newMovies: 'Новинки кино',
  movies: 'Фильмы',
  serials: 'Сериалы',
  cartoons: 'Мультфильмы',
  podcasts: 'Телепередачи',
  clips: 'Клипы',
  music: 'Музыка',
  audioBooks: 'Аудиокниги',
  favorites: 'Избранное',
  apps: 'Скачать приложение',

  // TV Guide
  tvGuide: 'Телепрограмма',
  monday: 'Пн',
  tuesday: 'Вт',
  wednesday: 'Ср',
  thursday: 'Чт',
  friday: 'Пт',
  saturday: 'Сб',
  sunday: 'Вс',
  live: 'В эфире',
  nextItem: 'Далее',
  evenLater: 'Еще позже',

  // Content genres
  freeTv: 'Бесплатные телеканалы',
  premiumTv: 'Платные телеканалы',
  freeRadio: 'Радиостанции',
  new: 'Новинки',
  last: 'Последнее добавленное',
  top: 'Популярное',
  recommended: 'Рекомендуемое',

  // Auth
  authEnter: 'Войти',
  authReg: 'Зарегистрироваться',
  rulesWarning: 'Нажимая кнопку "Зарегистрироваться" вы принимаете ',
  signIn: 'Вход',
  signUp: 'Регистрация',
  authAction: 'Войдите или зарегистрируйтесь',
  name: 'Ваше имя',
  email: 'Ваш email',
  emailOrLogin: 'Email или логин',
  login: 'Логин',
  password: 'Пароль',
  confirmPassword: 'Подтверждение пароля',
  needEmailConfirm: 'Регистрация прошла успешно, подтверждение отправлено на Ваш email',
  successfullyConfirm: 'Email успешно подтверждён',
  successfullyValidated: 'Пароль подтверждён, авторизуйтесь с новым паролем',
  errorConfirm: 'Ошибка активации email',
  errorValidate: 'Ошибка активации нового пароля',
  continue: 'Продолжить',
  save: 'Сохранить',
  forgotPassword: 'Забыли пароль?',
  resendConfirmEmail: 'Отправить письмо подтверждения повторно',

  // User
  logIn: 'Войти',
  logOut: 'Выход',
  id: 'Ваш ID:',
  balance: 'Ваш баланс:',
  expired: 'Подписка:',
  notActiveVoucher: 'не активна',
  profileData: 'Личный кабинет',
  settings: 'Настройки',
  subscribeChannelInfo: 'Для просмотра активируйте одну из подписок:',
  subscribeAction: 'Подписки',
  profileFavorites: 'Избранное',
  profileNoFavoritesData: 'Вы ничего не добавили в избранное',
  profilePay: 'Пополнить баланс',
  profilePaymentLabel: 'Сумма пополнения',
  profilePaymentService: 'Выберите способ оплаты',
  profileServices: 'Услуги и тарифы',
  profilePayAndBuy: 'Подписки',
  profilePayAndBuyForTv: 'Пополнить баланс',
  profileTransactions: 'Мои операции',
  profileEnterPromocode: 'Ввести промо-код',
  profileTransactionsDate: 'Дата',
  profileTransactionsDescr: 'Описание',
  profileTransactionsAmount: 'Сумма',
  profileSettings: 'Мои настройки',
  profileSettingsUpdateSuccess: 'Настройки успешно обновленны',

  // Footer
  about: 'О сервисе',
  licenses: 'Лицензии',
  rules: 'Пользовательское соглашение',
  contacts: 'Контакты',

  // PROMO
  promoCodeTitle: 'Активация промокода',
  promoCodeDescription: 'Активируйте Ваш промокод и получите доступ ко всем телеканалам',
  promoCodeHint: 'Введите промокод',
  promoCodeActivate: 'Активировать',
  promoCodeSuccessText: 'Ваш промокод успешно активирован',
  promoCodeSuccessMoneyText: 'Ваш бонус зачислен',
  promoCodeCallToWatch: 'Начать просмотр',
};
