import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Loc } from 'redux-react-i18n';
import Media from 'react-media';
import IconButton from 'components/mediabay-ui/components/IconButton';
import Logo from 'components/mediabay-ui/components/Logo';
import Dropdown from 'components/mediabay-ui/components/Dropdown';
import Avatar from 'components/mediabay-ui/components/Avatar';
// import Badge from 'components/mediabay-ui/components/Badge';
import Button from 'components/mediabay-ui/components/Button';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import RadioPlayer from '../RadioPlayer/RadioPlayer';
import UserDropdown from '../UserDropdown';
// import NotificationsDropdown from '../NotificationsDropdown';
import SearchForm from '../SearchForm';
import logoSvg from '../../assets/mb-logo-full.svg';
import telegramLogo from '../../assets/tg-logo.svg';
import facebookLogo from '../../assets/fb-logo.svg';
import instagramLogo from '../../assets/instagram-logo.svg';
import { StyledHeader, StyledIcon } from './styles';

class Header extends React.Component {
  static propTypes = {
    // Actions
    routerActions: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    getSearchData: PropTypes.func.isRequired,
    resetSearchData: PropTypes.func.isRequired,
    radioActions: PropTypes.shape({
      updateSelectedIndex: PropTypes.func,
      clearRadioPlayer: PropTypes.func,
    }).isRequired,
    clearAudios: PropTypes.func.isRequired,

    // Handlers
    sidebarToggleHandler: PropTypes.func.isRequired,
    // themeBtnHandler: PropTypes.func.isRequired,
    userSignOut: PropTypes.func.isRequired,

    // Data
    location: PropTypes.shape({
      state: PropTypes.object,
    }).isRequired,
    // notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
    userData: PropTypes.shape({
      isAuthorized: PropTypes.bool,
      profileData: PropTypes.shape({
        id: PropTypes.number,
        email: PropTypes.string,
        name: PropTypes.string,
        balance: PropTypes.string,
      }),
    }).isRequired,
    audios: PropTypes.shape({
      status: PropTypes.number,
      data: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    radioPlayerData: PropTypes.shape({
      selectedIndex: PropTypes.number,
      channels: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  }

  constructor() {
    super();

    this.state = {
      isSearchFormVisible: false,
      isAudiosExists: false,
    };

    this.container = React.createRef();

    this.openSearchHandler = this.openSearchHandler.bind(this);
    this.closeSearchHandler = this.closeSearchHandler.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.audios.status !== null) {
      return {
        isAudiosExists: true,
      };
    }

    if (nextProps.audios.status === null) {
      return {
        isAudiosExists: false,
      };
    }

    return null;
  }

  openSearchHandler() {
    this.setState({
      isSearchFormVisible: true,
    });
  }

  closeSearchHandler() {
    this.setState({
      isSearchFormVisible: false,
    });
  }

  render() {
    const {
      radioPlayerData,
      userData,
      sidebarToggleHandler,
      clearAudios,
      audios,
      radioActions,
      getSearchData,
      resetSearchData,
      routerActions,
      userSignOut,
      location,
    } = this.props;

    const { isSearchFormVisible, isAudiosExists } = this.state;
    return (
      <StyledHeader ref={this.container}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <IconButton
            icon="menu"
            width={25}
            height={25}
            onClick={sidebarToggleHandler}
            style={{ padding: '0 7px 0 5px' }}
          />
          <Link to="/">
            <Media query="(max-device-width: 520px)">
              {matches => (
                matches ? (
                  isSearchFormVisible ? <div /> : (
                    <Logo
                      height={25}
                      logoSrc={logoSvg}
                      altText="Mediabay Logo"
                    />
                  )
                ) : (
                  <Logo
                    height={30}
                    logoSrc={logoSvg}
                    altText="Mediabay Logo"
                  />
                )
              )}
            </Media>
          </Link>
        </div>

        <div>
          <Media query="(min-device-width: 768px)">
            {matches => (
              matches ? (
                <div>
                  {isAudiosExists && (
                    <AudioPlayer
                      clearAudios={clearAudios}
                      audios={audios}
                    />
                  )}

                  {radioPlayerData.channels && (
                    <RadioPlayer
                      updateSelectedIndex={radioActions.updateSelectedIndex}
                      clearRadioPlayer={radioActions.clearRadioPlayer}
                      data={radioPlayerData}
                    />
                  )}
                </div>
                ) : (
                  <div >
                    {radioPlayerData.channels && (
                      <div style={{
                        position: 'fixed',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: '#2c2e36',
                        padding: '10px 0',
                        boxShadow: '0 0 15px #111',
                        textAlign: 'center',
                      }}>
                        <RadioPlayer
                          updateSelectedIndex={radioActions.updateSelectedIndex}
                          clearRadioPlayer={radioActions.clearRadioPlayer}
                          data={radioPlayerData}
                          style={{ display: 'inline-flex', padding: 0 }}
                        />
                      </div>
                    )}
                  </div>
              )
            )}
          </Media>
        </div>

        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <a href="https://www.facebook.com/mediabayuzb/" target="_blank" rel="noopener noreferrer">
            <img
              src={facebookLogo}
              alt="Facebook"
              width="30px"
              height="30px"
              style={{ marginRight: 10 }}
            />
          </a>
          <a href="https://www.instagram.com/mediabay_tv/" target="_blank" rel="noopener noreferrer">
            <img
              src={instagramLogo}
              alt="Instagram"
              width="30px"
              height="30px"
              style={{ marginRight: 10, borderRadius: '50%' }}
            />
          </a>
          <a href="https://t.me/tvmediabay" target="_blank" rel="noopener noreferrer">
            <img
              src={telegramLogo}
              alt="Telegram"
              width="30px"
              height="30px"
              style={{ marginRight: 10 }}
            />
          </a>
          {
            isSearchFormVisible ? (
              <SearchForm
                getSearchData={getSearchData}
                resetSearchData={resetSearchData}
                closeSearchHandler={this.closeSearchHandler}
                routerActions={routerActions}
              />
            ) : (
              <StyledIcon
                icon="search"
                onClick={this.openSearchHandler}
              />
            )
          }
          {
            userData.isAuthorized ? (
              <Dropdown
                trigger={<Avatar />}
              >
                <UserDropdown
                  profileData={userData.profileData.data && userData.profileData.data.data}
                  userSignOut={userSignOut}
                />
              </Dropdown>
            ) : (
              <React.Fragment>
                <Media query="(max-device-width: 700px)">
                  {matches => (
                    matches ? (
                      <Link to={{ pathname: '/auth', state: { from: location } }}>
                        <StyledIcon icon="user" />
                      </Link>
                    ) : (
                      <Link
                        to={{ pathname: '/auth', state: { from: location } }}
                        style={{ margin: '0 10px' }}
                      >
                        <Button style={{ fontSize: 16, color: '#fff', borderColor: '#82858a' }}>
                          <Loc locKey="logIn" />
                        </Button>
                      </Link>
                    )
                  )}
                </Media>
              </React.Fragment>
            )
          }
        </div>
      </StyledHeader>
    );
  }
}

export default Header;
