export default {
  // Common
  common: {
    sidebarCollapsed: true,
    notifications: [],
  },

  // User
  user: {
    isAuthorized: false,
    hasVoucher: false,
    isExistsEmail: null,
    signUpFormData: {
      status: null,
      data: {},
    },
    transactions: {
      status: null,
      data: [],
    },
    availableVouchers: {
      status: null,
      data: [],
    },
    activeVouchers: {
      status: null,
      data: [],
    },
    profileData: {
      status: null,
      data: {
        status: null,
        data: {
          data: {
            id: 0,
            email: '',
            name: '',
            balance: '',
          },
        },
      },
    },
    isSettingsUpdated: false,
  },

  // Favorites
  favorites: {
    status: null,
    data: {
      tv: [],
      radio: [],
      movies: [],
      serials: [],
      cartoons: [],
      clips: [],
      music: [],
      podcasts: [],
      audiobooks: [],
    },
  },

  // Sliders
  sliders: {
    main: {
      status: null,
      data: [],
    },
    tv: {
      status: null,
      data: [],
    },
    radio: {
      status: null,
      data: [],
    },
    movies: {
      status: null,
      data: [],
    },
    serials: {
      status: null,
      data: [],
    },
    cartoons: {
      status: null,
      data: [],
    },
    clips: {
      status: null,
      data: [],
    },
    podcasts: {
      status: null,
      data: [],
    },
    audiobooks: {
      status: null,
      data: [],
    },
    music: {
      status: null,
      data: [],
    },
  },

  // Search
  search: {
    status: null,
    data: {
      channels: [],
      movies: [],
      serials: [],
      cartoons: [],
      clips: [],
      music: [],
      podcasts: [],
      audiobooks: [],
      actors: [],
      producer: [],
    },
  },

  // TV
  tv: {
    tvChannels: {
      status: null,
      data: [],
    },
    tvChannelsByCountry: {
      status: null,
      data: [],
    },
    tvChannel: {
      status: null,
      data: {},
    },
    tvChannelThread: {
      status: null,
      data: [],
    },
  },

  // Guide
  guide: {
    channelGuide: {
      status: null,
      data: [],
    },
    channelsGuide: {
      status: null,
      data: {},
    },
    weekdayChannelGuide: {
      status: null,
      currentId: null,
      data: [],
    },
  },

  // Radio
  radio: {
    radioChannels: {
      status: null,
      data: [],
    },
    player: {},
  },

  // Movies
  movies: {
    availableFilters: {
      status: null,
      data: {
        genres: [],
        countries: [],
        years: [],
        languages: [],
      },
    },
    filteredData: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    movies: {
      status: null,
      data: {},
    },
    movie: {
      status: null,
      data: {},
    },
    episodes: {
      status: null,
      data: [],
    },
    movieThread: {
      status: null,
      data: {
        media: null,
        preview: null,
      },
    },
    new: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    last: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    top: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    recommended: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
  },

  // Serials
  serials: {
    availableFilters: {
      status: null,
      data: {
        genres: [],
        countries: [],
        years: [],
        languages: [],
      },
    },
    filteredData: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    serials: {
      status: null,
      data: {},
    },
    serial: {
      status: null,
      data: {},
    },
    episodes: {
      status: null,
      data: [],
    },
    serialThread: {
      status: null,
      data: {
        media: null,
        preview: null,
      },
    },
    new: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    last: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    top: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    recommended: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
  },

  // Cartoons
  cartoons: {
    availableFilters: {
      status: null,
      data: {
        genres: [],
        countries: [],
        years: [],
        languages: [],
      },
    },
    filteredData: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    cartoons: {
      status: null,
      data: {},
    },
    cartoon: {
      status: null,
      data: {},
    },
    episodes: {
      status: null,
      data: [],
    },
    cartoonThread: {
      status: null,
      data: {
        media: null,
        preview: null,
      },
    },
    new: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    last: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    top: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    recommended: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
  },

  // Clips
  clips: {
    availableFilters: {
      status: null,
      data: {
        genres: [],
        countries: [],
        years: [],
        languages: [],
      },
    },
    filteredData: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    clip: {
      status: null,
      data: {},
    },
    clipThread: {
      status: null,
      data: {},
    },
    new: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    last: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    top: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    recommended: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
  },

  // Music
  music: {
    availableFilters: {
      status: null,
      data: {
        genres: [],
        countries: [],
        years: [],
        languages: [],
      },
    },
    filteredData: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    album: {
      status: null,
      data: {},
    },
    albumThreads: {
      status: null,
      data: {},
    },
    new: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    last: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    top: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    recommended: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
  },

  // Podcasts
  podcasts: {
    availableFilters: {
      status: null,
      data: {
        genres: [],
        countries: [],
        years: [],
        languages: [],
      },
    },
    filteredData: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },

    podcast: {
      status: null,
      data: [],
    },

    podcasts: {
      status: null,
      data: {},
    },
    episodes: {
      status: null,
      data: [],
    },
    podcastThread: {
      status: null,
      data: {
        media: null,
        preview: null,
      },
    },
    new: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    last: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    top: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    recommended: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
  },

  // Audiobooks
  audiobooks: {
    availableFilters: {
      status: null,
      data: {
        genres: [],
        countries: [],
        years: [],
        languages: [],
      },
    },
    filteredData: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    audiobook: {
      status: null,
      data: {},
    },
    audiobookThread: {
      status: null,
      data: {},
    },
    new: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    last: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    top: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
    recommended: {
      status: null,
      pageLoaded: 1,
      hasNext: true,
      data: [],
    },
  },

  actor: {
    status: null,
    data: [],
  },

  // Media details
  contentDetails: {
    mediaDetails: {
      status: null,
      data: {},
    },
    albumDetails: {
      status: null,
      data: {},
    },
  },

  // Filter
  filter: {
    content: {
      status: null,
      pages: null,
      data: [],
    },
    movies: {
      status: null,
      data: {
        genres: [],
        countries: [],
        years: [],
        languages: [],
      },
    },
    cartoons: {
      status: null,
      data: {
        genres: [],
        countries: [],
        years: [],
        languages: [],
      },
    },

    serials: {
      status: null,
      data: {
        genres: [],
        countries: [],
        years: [],
        languages: [],
      },
    },

    podcasts: {
      status: null,
      data: {
        genres: [],
        countries: [],
        years: [],
        languages: [],
      },
    },

    clips: {
      status: null,
      data: {
        genres: [],
        countries: [],
        years: [],
        languages: [],
      },
    },
  },

  // Article
  article: {
    status: null,
    data: {},
  },

  // Audios
  audios: {
    status: null,
    data: [],
  },
};
